import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-apis',
  templateUrl: './list-apis.component.html',
  styleUrls: ['./list-apis.component.css']
})
export class ListApisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

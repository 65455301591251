import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ListApisComponent } from './list-apis/list-apis.component';
import { ListApiResourcesComponent } from './list-api-resources/list-api-resources.component';
import { ResourceDetailComponent } from './resource-detail/resource-detail.component';
import { EndpointDetailComponent } from './endpoint-detail/endpoint-detail.component';
import { RedirectGuard } from './redirect.guard';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { GixPageComponent } from './gix-page/gix-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'form', component: ApplicationFormComponent },
  { path: 'gix', component: GixPageComponent },
  // { path: 'list-apis', component: ListApisComponent },
  // {
  //   path: 'list-resources', component: ListApiResourcesComponent,
  //   children: [
  //     { path: 'resource', component: ResourceDetailComponent },
  //     { path: 'resource/:id', component: ResourceDetailComponent },
  //     { path: 'endpoint', component: EndpointDetailComponent },
  //   ]
  // },
  // {
  //   path: 'gix',
  //   pathMatch: 'full',
  //   canActivate: [RedirectGuard],
  //   component: HomeComponent,
  //   data: {
  //     externalUrl: 'https://apiuat.bcr.com.ar/gix/'
  //   }
  // },
  // {
  //   path: 'maestros',
  //   pathMatch: 'full',
  //   canActivate: [RedirectGuard],
  //   component: HomeComponent,
  //   data: {
  //     externalUrl: 'https://apiuat.bcr.com.ar/maestros/'
  //   }
  // },
  // {
  //   path: 'muvin',
  //   pathMatch: 'full',
  //   canActivate: [RedirectGuard],
  //   component: HomeComponent,
  //   data: {
  //     externalUrl: 'https://apiuat.bcr.com.ar/muvin/'
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-endpoint-detail',
  templateUrl: './endpoint-detail.component.html',
  styleUrls: ['./endpoint-detail.component.css']
})
export class EndpointDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gix-page',
  templateUrl: './gix-page.component.html',
  styleUrls: ['./gix-page.component.css']
})
export class GixPageComponent implements OnInit {

  public urlGix = environment.urlGix;
  public urlMaestros = environment.urlMaestros;

  constructor() { }

  ngOnInit() {
  }

}

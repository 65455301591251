import { BrowserModule, } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { ListApisComponent } from './list-apis/list-apis.component';
import { ListApiResourcesComponent } from './list-api-resources/list-api-resources.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { EndpointDetailComponent } from './endpoint-detail/endpoint-detail.component';
import { ResourceDetailComponent } from './resource-detail/resource-detail.component';
import { RedirectGuard } from './redirect.guard';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { GixPageComponent } from './gix-page/gix-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ListApisComponent,
    ListApiResourcesComponent,
    EndpointDetailComponent,
    ResourceDetailComponent,
    ApplicationFormComponent,
    GixPageComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgxPageScrollModule
  ],
  providers: [RedirectGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
